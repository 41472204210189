import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ProjectPage from 'components/ProjectPage'

const Project = ({ data }) => (
  <>
    <Helmet title='Cab | François Deguire' />
    <ProjectPage
      name='Cab'
      description={`
        Singapore aims to triple the number of bicycle lanes it already has
        by 2020 and to improve the cycling infrastructure and bike-sharing.
        Its goal is to combat traffic congestion and ease the burden on public transport.
        One of the factors limiting the adoption rate of these services is that it is
        difficult to move larger loads using traditional bikes. Cab is redefining
        some of the paradigms of these services by offering an electrically
        assisted cargo bike in a user-friendly and distinctive package.
      `}
      meta={`
        For Université de Montréal<br>
        In 2018
      `}
      images={Object.values(data).map(v => v.childImageSharp.fluid)}
    />
  </>
)

export const query = graphql`
  query {
    cab1: file(relativePath: { eq: "cab/1.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cab2: file(relativePath: { eq: "cab/2.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cab3: file(relativePath: { eq: "cab/3.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cab4: file(relativePath: { eq: "cab/4.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    cab5: file(relativePath: { eq: "cab/5.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Project
